import { all, call, put, takeEvery } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import GetContentTypeSchema from '../../rest/requests/content-types-builder/getContentTypeSchema'
import add from '../../rest/requests/answer/add'
import getAnswers from '../../rest/requests/classifieds/getAnswers'
import deleteAnswer from '../../rest/requests/classifieds/deleteAnswer'
import archiveAnswer from '../../rest/requests/answer/archive'
import { ClassifiedCategoryId } from '../../types/classified'
import tracking from '../../tracking'

import { adsReplyFormTransformer } from './transformers'

export default class AnswerSaga {
  static *onAddAnswerRequest({
    payload,
  }: ReturnType<typeof actions.answer.addAnswerRequest>) {
    const transformed: ReturnType<typeof adsReplyFormTransformer> = yield call(
      adsReplyFormTransformer,
      { values: payload }
    )

    const response = yield* ApiSagas.rest(add(transformed))

    if (response.data) {
      yield put(actions.answer.addAnswerSuccess(response.data))
      if (
        String(payload?.fullClassified?.classifiedCategory?.id) !==
        ClassifiedCategoryId.Liberal
      ) {
        yield put(
          actions.chat.createNewChannel({
            message: payload.message,
            classifiedAuthor: payload?.fullClassified,
            response: response.data,
          })
        )
        yield call(tracking.conversation_start, payload)
      }
    } else {
      yield put(actions.answer.addAnswerError(response.errors))
    }
  }

  static *onGetAnswerSchema() {
    const response = yield* ApiSagas.rest(
      GetContentTypeSchema({ uid: 'api::classified-answer.classified-answer' })
    )

    if (response.data) {
      yield put(actions.answer.getAnswerSchemaSuccess(response.data))
    } else {
      yield put(actions.answer.getAnswerSchemaReset(response.errors))
    }
  }

  static *onGetAnswers({
    payload,
  }: ReturnType<typeof actions.answer.getAnswersRequest>) {
    const response = yield* ApiSagas.rest(getAnswers(payload))

    if (!response.errors && response.data) {
      yield put(actions.answer.getAnswersSuccess(response.data))
    } else {
      yield put(actions.answer.getAnswersError(response.errors))
    }
  }

  static *onDeleteAnswer({
    payload,
  }: ReturnType<typeof actions.answer.deleteAnswerRequest>) {
    const response = yield* ApiSagas.rest(deleteAnswer(payload))

    if (!response.errors && response.data) {
      yield put(actions.answer.deleteAnswerSuccess(response.data))
    } else {
      yield put(actions.answer.deleteAnswerError(response.errors))
    }
  }

  static *onArchiveAnswer({
    payload,
  }: ReturnType<typeof actions.answer.archiveAnswerRequest>) {
    const response = yield* ApiSagas.rest(archiveAnswer(payload))

    if (!response.errors && response.data) {
      yield put(actions.answer.archiveAnswerSuccess(response.data))
    } else {
      yield put(actions.answer.archiveAnswerError(response.errors))
    }
  }

  static *loop() {
    yield all([
      takeEvery(actions.answer.getAnswerSchemaRequest, this.onGetAnswerSchema),
      takeEvery(actions.answer.addAnswerRequest, this.onAddAnswerRequest),
      takeEvery(actions.answer.getAnswersRequest, this.onGetAnswers),
      takeEvery(actions.answer.deleteAnswerRequest, this.onDeleteAnswer),
      takeEvery(actions.answer.archiveAnswerRequest, this.onArchiveAnswer),
    ])
  }
}
