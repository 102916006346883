import { all, put, call, takeEvery } from 'redux-saga/effects'

import ApiSagas from '../api/sagas'
import { actions } from '../index'
import getProfiles from '../../rest/requests/user/getProfiles'
import getAlerts from '../../rest/requests/classifieds/getAlerts'
import deleteAlert from '../../rest/requests/classifieds/deleteAlert'
import updateAlert from '../../rest/requests/classifieds/updateAlert'
import createAlert from '../../rest/requests/classifieds/createAlert'
import getSpecialities from '../../rest/requests/user/getSpecialities'
import tracking from '../../tracking'

export default class UserSagas {
  static *onGetUserProfilesRequest() {
    const response = yield* ApiSagas.rest(getProfiles())

    if (response.errors) {
      yield put(actions.user.getUserProfilesError(response.data))
      return
    }

    if (response.data) {
      yield put(actions.user.getUserProfilesSuccess(response.data))
    }
  }

  static *onGetUserSpecialitiesRequest() {
    const response = yield* ApiSagas.rest(getSpecialities())

    if (response.errors) {
      yield put(actions.user.getUserSpecialitiesError(response.data))
      return
    }

    if (response.data) {
      yield put(actions.user.getUserSpecialitiesSuccess(response.data))
    }
  }

  static *onGetAlerts({
    payload,
  }: ReturnType<typeof actions.user.getAlertsRequest>) {
    const response = yield* ApiSagas.rest(getAlerts(payload))

    if (!response.errors && response.data) {
      yield put(actions.user.getAlertsSuccess(response.data))
    } else {
      yield put(actions.user.getAlertsError(response.errors))
    }
  }

  static *onDeleteAlert({
    payload,
  }: ReturnType<typeof actions.user.deleteAlertRequest>) {
    const response = yield* ApiSagas.rest(deleteAlert(payload))

    if (!response.errors && response.data) {
      yield put(actions.user.deleteAlertSuccess(response.data))
    } else {
      yield put(actions.user.deleteAlertError(response.errors))
    }
  }

  static *onCreateAlert({
    payload,
  }: ReturnType<typeof actions.user.createAlertRequest>) {
    const response = yield* ApiSagas.rest(createAlert(payload))

    if (!response.errors && response.data) {
      yield put(actions.user.createAlertSuccess(response.data))
      yield call(tracking.alert_creation, response.data)
    } else {
      yield put(actions.user.createAlertError(response.errors))
    }
  }

  static *onUpdateAlert({
    payload,
  }: ReturnType<typeof actions.user.updateAlertRequest>) {
    const response = yield* ApiSagas.rest(updateAlert(payload))

    if (!response.errors && response.data) {
      yield put(actions.user.updateAlertSuccess(response.data))
    } else {
      yield put(actions.user.updateAlertError(response.errors))
    }
  }

  static *loop() {
    yield all([
      takeEvery(
        actions.user.getUserProfilesRequest,
        this.onGetUserProfilesRequest
      ),
      takeEvery(
        actions.user.getUserSpecialitiesRequest,
        this.onGetUserSpecialitiesRequest
      ),
      takeEvery(actions.user.getAlertsRequest, this.onGetAlerts),
      takeEvery(actions.user.updateAlertRequest, this.onUpdateAlert),
      takeEvery(actions.user.deleteAlertRequest, this.onDeleteAlert),
      takeEvery(actions.user.createAlertRequest, this.onCreateAlert),
    ])
  }
}
