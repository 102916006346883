import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type ToggleReservationResponse = {
  reservation: {
    user: string
    offer: {
      id: string
    }
    email: string
  }
  status: boolean
}

export type ToggleReservationParams = {
  offer: string
}

const toggleReservation = (data: ToggleReservationParams) =>
  postRestRequest({
    url: api.REST_API_URL + '/reservation/toggle',
    data,
    transformer: (response: ToggleReservationResponse) => {
      return response
    },
  })

export default toggleReservation
