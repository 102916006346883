import dayjs from 'dayjs'

export const readableDate = (date: string, format?: string) => {
  const defaultFormat = format ? format : 'DD/MM/YYYY'
  return dayjs(date).format(defaultFormat)
}

export const humanDate = (date: string, fullMonth?: boolean) => {
  return dayjs(date).format(fullMonth ? 'DD MMMM YYYY' : 'DD MMM YYYY')
}

export const isInPast = (date: string) => {
  return dayjs(date).isBefore()
}
