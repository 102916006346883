import qs from 'qs'

import { postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SAlert } from '../../types/api/Alert'
import { classifiedAlertTransformer } from '../../transformers/classifiedTransformer'

export type CreateAlertResponse = SimpleResult<SAlert>

export type CreateAlertParams = {
  userId: string
  specialities: string
  categories: string
  localisation: string
  terms: string
}

const createAlert = ({
  userId,
  specialities,
  categories,
  localisation,
  terms,
}: CreateAlertParams) => {
  return postRestRequest({
    url:
      api.REST_API_URL +
      `/classified-alerts?${qs.stringify({
        populate: {
          specialities: {
            fields: ['name'],
          },
          categories: {
            fields: ['name'],
          },
          user: {
            fields: ['id'],
          },
        },
      })}`,
    data: {
      data: {
        specialities: specialities,
        categories: categories,
        localisation: localisation,
        terms: terms,
        user: userId,
      },
    },
    transformer: (response: CreateAlertResponse) => {
      return classifiedAlertTransformer(response.data)
    },
  })
}

export default createAlert
