import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import dayjs from 'dayjs'
import { REHYDRATE } from 'redux-persist'
import {
  LoginMethodParams,
  LoginMethodResponse,
} from 'src/rest/requests/auth/loginMethod'

import type { RootState } from '../store'
import { getCustomService } from '../../helpers/ReduxHelpers'
import { Provider, User } from '../../rest/types/User'
import { LoginParams, LoginResponse } from '../../rest/requests/auth/login'
import {
  RegisterParams,
  RegisterResponse,
} from '../../rest/requests/auth/register'
import {
  EmailLoginParams,
  EmailLoginResponse,
} from '../../rest/requests/auth/emailLogin'
import {
  ForgottenPasswordParams,
  ForgottenPasswordResponse,
} from '../../rest/requests/auth/forgottenPassword'
import {
  ResetPasswordParams,
  ResetPasswordResponse,
} from '../../rest/requests/auth/resetPassword'
import {
  UpdateProfileParams,
  UpdateProfileResponse,
} from '../../rest/requests/auth/updateProfile'

import { actionTypes } from './types'

//
// requests
//

export const loginService = getCustomService<
  'login',
  LoginResponse,
  LoginParams
>('login')

export const loginImpersonateService = getCustomService<
  'loginImpersonate',
  boolean,
  { jwt: string }
>('loginImpersonate')

export const emailLoginService = getCustomService<
  'emailLogin',
  EmailLoginResponse,
  EmailLoginParams
>('emailLogin')

export const registerService = getCustomService<
  'register',
  RegisterResponse,
  RegisterParams
>('register')

export const forgottenPasswordService = getCustomService<
  'forgottenPassword',
  ForgottenPasswordResponse,
  ForgottenPasswordParams
>('forgottenPassword')

export const resetPasswordService = getCustomService<
  'resetPassword',
  ResetPasswordResponse,
  ResetPasswordParams
>('resetPassword')

export const updateProfileService = getCustomService<
  'updateProfile',
  UpdateProfileResponse,
  UpdateProfileParams
>('updateProfile')

export const loginMethodService = getCustomService<
  'loginMethod',
  LoginMethodResponse,
  LoginMethodParams
>('loginMethod')

//
// Initial state
//

export type AuthState = {
  user: User | null
  token: string | null
  impersonate?: boolean
  meIsLoading?: boolean
  // requests
  login: typeof loginService.state
  loginImpersonate: typeof loginImpersonateService.state
  emailLogin: typeof emailLoginService.state
  register: typeof registerService.state
  forgottenPassword: typeof forgottenPasswordService.state
  resetPassword: typeof resetPasswordService.state
  updateProfile: typeof updateProfileService.state
  loginMethod: typeof loginMethodService.state
}

const services = {
  login: loginService.state,
  loginImpersonate: loginImpersonateService.state,
  emailLogin: emailLoginService.state,
  register: registerService.state,
  forgottenPassword: forgottenPasswordService.state,
  resetPassword: resetPasswordService.state,
  updateProfile: updateProfileService.state,
  loginMethod: loginMethodService.state,
}

const initialState: AuthState = {
  user: null,
  token: null,
  impersonate: false,
  meIsLoading: true,
  // requests
  ...services,
}

//
// Slice (Actions & Reducers)
//

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setUser: (state, { payload }: actionTypes.setUser) => {
      state.user = payload
    },
    setToken: (state, action: actionTypes.setToken) => {
      state.token = action.payload
    },
    setImpersonate: (state, action: actionTypes.setImpersonate) => {
      state.impersonate = action.payload
    },
    logout: () => undefined,
    meIsLoading: (state, action: actionTypes.setMeIsLoading) => {
      state.meIsLoading = action.payload
    },
    me: () => undefined,
    visitOnboarding: () => undefined,
    ...loginService.reducers,
    ...loginImpersonateService.reducers,
    ...emailLoginService.reducers,
    ...registerService.reducers,
    ...forgottenPasswordService.reducers,
    ...resetPasswordService.reducers,
    ...updateProfileService.reducers,
    ...loginMethodService.reducers,
  },
  extraReducers: (builder) =>
    builder.addCase<any, PayloadAction<any>>(
      REHYDRATE,
      (_state, action: PayloadAction<any>) => {
        return {
          ...initialState,
          ...(action.payload?.['auth'] ?? {}),
          ...services,
          meIsLoading: true,
        }
      }
    ),
})

export const { reducer, actions } = slice

//
// Selectors
//

const root = (state: RootState) => state[slice.name]
const user = (state: RootState) => (state.app.isInit ? root(state).user : null)
const impersonate = (state: RootState) => root(state).impersonate
const token = (state: RootState) => root(state).token
const meIsLoading = (state: RootState) => root(state).meIsLoading
const jwt = createSelector([token], (tokenString): JwtPayload | null => {
  return tokenString ? jwtDecode<JwtPayload>(tokenString) : null
})
const isConnected = createSelector([token, user], (t, u): boolean => {
  return !!t && t !== '' && !!u?.id
})
const isSubscriptionActive = createSelector([isConnected, user], (c, u) => {
  return (
    c &&
    !!u?.subscriptionExpiration &&
    dayjs().isBefore(dayjs(u?.subscriptionExpiration))
  )
})
const login = (state: RootState) => root(state).login
const loginImpersonate = (state: RootState) => root(state).loginImpersonate
const emailLogin = (state: RootState) => root(state).emailLogin
const register = (state: RootState) => root(state).register
const forgottenPassword = (state: RootState) => root(state).forgottenPassword
const resetPassword = (state: RootState) => root(state).resetPassword
const updateProfile = (state: RootState) => root(state).updateProfile
const loginMethod = (state: RootState) => root(state).loginMethod

const hasRrpsNumber = createSelector(
  [user],
  (u) => u?.username && [Provider.Ecps, Provider.EcpsBas].includes(u.provider)
)
const hasAccessToStore = createSelector([user], (u) =>
  u?.role?.type === 'partial' ? false : true
)
const sessionId = createSelector([user, jwt], (u, j): string => {
  return u?.id + '-' + j?.iat
})

export const selectors = {
  user,
  impersonate,
  token,
  jwt,
  isConnected,
  isSubscriptionActive,
  login,
  loginMethod,
  loginImpersonate,
  emailLogin,
  register,
  meIsLoading,
  forgottenPassword,
  resetPassword,
  updateProfile,
  hasRrpsNumber,
  hasAccessToStore,
  sessionId,
}
