import type { ClassifiedAlert, ClassifiedEmitter } from './Classified'
import type { Feedback } from './Feedback'
import type { Offer } from './Offer'
import { UserProfile } from './UserProfile'
import { SImageData } from './api/Image'

type UserFeedback = Feedback & { offer: Offer }

export enum Civility {
  Dr = 'dr',
  Mrs = 'mrs',
  Mr = 'mr',
}

export enum Provider {
  Local = 'local',
  Ecps = 'ecps',
  EcpsBas = 'ecps_bas',
  Cps = 'cps',
  Email = 'email',
}

export type User = {
  id: string
  username: string
  email: string
  provider: Provider
  confirmed: boolean
  blocked: boolean
  createdAt: string
  updatedAt: string
  firstName: string
  lastName: string
  subscriptionExpiration: string
  avatar: SImageData
  feedbacks?: UserFeedback[]
  offersFeedbackIds?: Array<string | number>
  company?: string
  address?: string
  postcode?: string
  rpps_number?: string
  civility?: Civility
  classified_emitters?: Array<ClassifiedEmitter>
  city?: string
  siret?: string
  cgu?: boolean
  onboarded?: boolean
  profile?: UserProfile
  speciality: UserSpeciality
  testOffered?: 'oui' | 'non'
  role?: {
    id?: string
    type?: string
    name?: string
  }
  alerts: ClassifiedAlert[]
  sessionId: string
}

export type UserSpeciality = {
  id: string
  name?: string
}
