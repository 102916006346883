import qs from 'qs'

import { putRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'
import { SimpleResult } from '../../types/PaginatedResult'
import { SClassified } from '../../types/api/Classified'
import { classifiedTransformer } from '../../transformers/classifiedTransformer'

type updateResponse = SimpleResult<SClassified>

export type UpdateParams = {
  id: string
  data: any
  files?: Array<{ name: string; value: File }>
}

const update = ({ id, data, files }: UpdateParams) => {
  const formData = new FormData()

  if (files?.length) {
    for (const file of files) {
      formData.append(file.name, file.value)
    }
  }

  formData.append('data', JSON.stringify(data))

  return putRestRequest({
    url:
      api.REST_API_URL +
      `/classifieds/${id}?${qs.stringify({
        populate: {
          user: {
            fields: ['id'],
          },
          classifiedCategory: {
            fields: ['name'],
          },
        },
      })}`,
    data: formData,
    transformer: (response: updateResponse) => {
      return classifiedTransformer(response.data)
    },
  })
}

export default update
