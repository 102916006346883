import {
  all,
  call,
  delay,
  put,
  takeEvery,
  takeLeading,
} from 'redux-saga/effects'

import { actions } from '..'
import ApiSagas from '../api/sagas'
import feedbacks from '../../rest/requests/feedbacks/feedbacks'
import addFeedback from '../../rest/requests/feedbacks/addFeedback'
import updatedFeedback from '../../rest/requests/feedbacks/updateFeedback'
import deleteFeedback from '../../rest/requests/feedbacks/deleteFeedback'
import tracking from '../../tracking'

export default class FeedbacksSagas {
  static *onLoadRequest({
    payload,
  }: ReturnType<typeof actions.feedbacks.loadRequest>) {
    const response = yield* ApiSagas.rest(feedbacks(payload))

    if (response.data) {
      yield put(actions.feedbacks.loadSuccess(response.data))
    } else {
      yield put(actions.feedbacks.loadError(response.errors))
    }
  }

  static *onAddFeedbackRequest({
    payload,
  }: ReturnType<typeof actions.feedbacks.addFeedbackRequest>) {
    const response = yield* ApiSagas.rest(addFeedback(payload))

    if (response.data) {
      yield delay(500)
      yield put(actions.feedbacks.addFeedbackSuccess(response.data))
      yield call(tracking.review_submit, payload)
    } else {
      yield put(actions.feedbacks.addFeedbackError(response.errors))
    }
  }

  static *onUpdatedFeedbackRequest({
    payload,
  }: ReturnType<typeof actions.feedbacks.updatedFeedbackRequest>) {
    const response = yield* ApiSagas.rest(updatedFeedback(payload))

    if (response.data && !response.data?.error) {
      yield delay(500)
      yield put(actions.feedbacks.updatedFeedbackSuccess(response.data))
    } else {
      yield put(
        actions.feedbacks.updatedFeedbackError(
          response.errors || response?.data?.error
        )
      )
    }
  }

  static *onDeleteFeedbackRequest({
    payload,
  }: ReturnType<typeof actions.feedbacks.deleteFeedbackRequest>) {
    const response = yield* ApiSagas.rest(deleteFeedback(payload))

    if (response.data && !response.data?.error) {
      yield delay(500)
      yield put(actions.feedbacks.deleteFeedbackSuccess(response.data))
    } else {
      yield put(
        actions.feedbacks.deleteFeedbackError(
          response.errors || response?.data?.error
        )
      )
    }
  }

  static *loop() {
    yield all([
      takeEvery(actions.feedbacks.loadRequest, this.onLoadRequest),
      takeLeading(
        actions.feedbacks.addFeedbackRequest,
        this.onAddFeedbackRequest
      ),
      takeLeading(
        actions.feedbacks.updatedFeedbackRequest,
        this.onUpdatedFeedbackRequest
      ),
      takeLeading(
        actions.feedbacks.deleteFeedbackRequest,
        this.onDeleteFeedbackRequest
      ),
    ])
  }
}
