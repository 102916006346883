import { dataToFormData, postRestRequest } from '../../../helpers/RestHelpers'
import { api } from '../../../configuration'

export type LoginMethodResponse = {
  data: {
    provider: string
    error?: any
  }
}

export type LoginMethodParams = {
  email: string
}

const loginMethod = (params: LoginMethodParams) =>
  postRestRequest({
    url: api.REST_API_URL + `/onboarding/login-method`,
    data: dataToFormData(params),
    transformer: (data: any): any => {
      return data
    },
  })

export default loginMethod
