import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { selectors } from '../../redux'
import tracking from '../../tracking'
import { Statistics } from '../../rest/types/Statistics'

const PageTracking = ({ statistics }: { statistics?: Statistics }) => {
  const router = useRouter()
  const user = useSelector(selectors.auth.user)
  const userLoading = useSelector(selectors.auth.meIsLoading)
  const pageLoadEventSent = useRef<boolean>(false)

  useEffect(() => {
    const handleRouteChange = () => {
      tracking.pageview()
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events, user])

  useEffect(() => {
    if (userLoading === false && pageLoadEventSent.current === false) {
      tracking.pageview(user, statistics)
      pageLoadEventSent.current = true
    }
  }, [statistics, userLoading, user])

  return null
}

export default PageTracking
